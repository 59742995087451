import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ScrollListener from './Atom/ScrollListener'
import { StaticQuery, graphql } from 'gatsby'
import ShareCard from '../images/share-card.png'
import styled from 'styled-components'
import Nav from './Organism/Nav'

import '../styles/theme.scss'

const rootUrl = 'https://utomia.world'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => {
      const title = data.site.siteMetadata.title
      const description = data.site.siteMetadata.description
      return (
        <>
          <Helmet
            title={title}
            meta={[
              {
                name: 'keywords',
                content:
                  'Utomia, book, YA, kids book, Neil Mullins, Caitlin Scholl',
              },
              { name: 'name', content: title },
              { name: 'description', content: description },
              { property: 'og:title', content: title },
              { property: 'og:description', content: description },
              { property: 'og:type', content: 'website' },
              { name: 'twitter:card', content: 'summary_large_image' },
              { name: 'twitter:title', content: title },
              { name: 'twitter:description', content: description },
              { property: 'og:image', content: `${rootUrl}${ShareCard}` },
              { name: 'twitter:image', content: `${rootUrl}${ShareCard}` },
              { property: 'og:url', content: rootUrl },
            ]}
          >
            <html lang="en" />
            <link rel="stylesheet" href="https://use.typekit.net/upl7fbp.css" />
          </Helmet>
          <ScrollListener>
            <Nav />
            <div>{children}</div>
          </ScrollListener>
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
