import React from 'react'
import styled from 'styled-components'
import Link from '../Atom/Link'
import { navigate } from 'gatsby'
import ScrollListener from '../Atom/ScrollListener'
import * as mediaQueries from '../../styles/js/mediaQueries'

const height = 6

const Container = styled.div`
  align-self: center;
`

const Item = styled(Link)`
  transition: color 0.3s ease;
  padding: 0 20px;
  &:hover {
    color: var(--color-text-gold);
  }
  ${mediaQueries.largeAndBelow} {
    color: var(--color-text-white);
    opacity: 1;
    display: block;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 0;
  }
`

class JumpNav extends React.Component {
  static height = height

  state = {
    ignoreScroll: false,
  }

  makeHandleClick = (doScroll, onToggleClick) => e => {
    const hash = e.target.hash
    const target = document.querySelector(hash)
    if (target) {
      e.preventDefault()

      // Tell scroll listener to ignore until we're done scrolling
      doScroll(target.offsetTop, 500).then(() => navigate(hash))
      if (window.innerWidth < mediaQueries.largeBreakpoint) {
        onToggleClick()
      }
    }
  }

  render() {
    const { ignoreScroll } = this.state
    const { items = [], onToggleClick } = this.props
    return (
      <ScrollListener.Consumer>
        {({ scrolledUp, doScroll }) => (
          <Container isVisible={scrolledUp}>
            {items.map(item => (
              <Item
                to={item.id}
                key={item.id}
                onClick={this.makeHandleClick(doScroll, onToggleClick)}
                className="no-border h2"
              >
                {item.displayName}
              </Item>
            ))}
          </Container>
        )}
      </ScrollListener.Consumer>
    )
  }
}

export default JumpNav
