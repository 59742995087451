import React from 'react';
import _ from 'lodash';

const componentForCondition = (condition, trueComponent, falseComponent) => {
  return condition ? trueComponent : falseComponent;
}

const TernaryComponent = ({ condition, trueComponent, falseComponent, propsForCondition = _.noop, ...restProps }) => {
  const Component = componentForCondition(condition, trueComponent, falseComponent);

  return <Component {...propsForCondition(condition, restProps)}  />;
}

export default TernaryComponent;