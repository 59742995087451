import React from 'react';
import TernaryComponent from './TernaryComponent';
import GatsbyLink from 'gatsby-link';

const URL_LIKE_REGEX =  /^((http(s)?)|mailto|ftp):/i;

const isExternalUrl = url => URL_LIKE_REGEX.test(url);

const propsForUrl = (isExternal, { to, ...restProps }) => {
  const conditionalProps = isExternal ? {
    href: to,
    target: '_blank',
    rel: 'noopener',
  } : {
    to
  };

  return Object.assign(conditionalProps, restProps);
}

const Link = ({ to, ...restProps }) => {
  return <TernaryComponent
    condition={isExternalUrl(to)}
    trueComponent='a'
    falseComponent={GatsbyLink}
    propsForCondition={propsForUrl}
    to={to}
    {...restProps}
  />;
}

export {
  isExternalUrl,
  Link as default
};
