import React from 'react'
import styled from 'styled-components'
import JumpNav from '../Molecule/JumpNav'
import LogoSvg from '../svg/logo.svg'
import DiamondNavSvg from '../svg/diamond-nav.svg'
import FloatAnimation from '../Atom/FloatAnimation'
import CrystalButton from '../Atom/CrystalButton'
import { allStaggeredTransitionStyles } from '../Atom/TextTransitions'
import * as mediaQueries from '../../styles/js/mediaQueries'
import ScrollListener from '../Atom/ScrollListener'
import { Link } from 'gatsby'

const jumpNavItems = [
  {
    displayName: 'preface',
    id: '#preface',
  },
  {
    displayName: 'the world',
    id: '#world',
  },
  {
    displayName: 'characters',
    id: '#characters',
  },
  {
    displayName: 'where to buy',
    id: '#where-to-buy',
  },
  // {
  //   displayName: 'authors',
  //   id: '#authors',
  // },
]

const Container = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 100%;
  background: transparent;
  position: fixed;
  top: -120px;
  left: 0;
  z-index: 100;
  transition: top 0.3s ease;
  ${({ isVisible, hasScrolled }) =>
    isVisible &&
    `
    top: 0px;
    background: ${hasScrolled ? 'rgba(255, 255, 255, 0.7)' : 'transparent'};
  `};
`

const NavWrap = styled.div`
  background: transition;
  ${mediaQueries.largeAndBelow} {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 480px;
    height: 100%;
    background: var(--color-bg-blue);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-100%);
    transition: transform 500ms cubic-bezier(0.32, 0.24, 0.15, 1);
    ${props =>
      props['data-open'] &&
      `
      transform: translateX(0);
    `} & > div {
      ${props =>
        allStaggeredTransitionStyles({
          inView: props['data-open'],
          stagger: 100,
          outDuration: 100,
        })};
    }
  }
  ${mediaQueries.smallAndBelow} {
    width: 100%;
  }
`

const NavButton = styled.button`
  display: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
  svg {
    .crystal-btn-stroke,
    .crystal-btn-bg {
      transition: stroke 0.3s ease-in, fill 0.3s ease-in;
    }
  }
  &:hover {
    svg {
      .crystal-btn-stroke {
        stroke: var(--color-ui-dark-purple);
      }
      .crystal-btn-bg {
        fill: var(--color-ui-purple);
      }
    }
  }
  ${mediaQueries.largeAndBelow} {
    display: block;
    margin-right: auto;
    margin-left: 6.25%;
    animation: ${FloatAnimation} 4s ease-in-out infinite;
  }
`

const StyledLink = styled(Link)`
  margin-right: auto;
  margin-left: 4.1%;
  ${mediaQueries.largeAndBelow} {
    margin: 0;
  }
`

const CrystalButtonWrap = styled.div`
  margin-left: auto;
  margin-right: 4.1%;
  ${mediaQueries.largeAndBelow} {
    margin-right: 6.25%;
  }
`

const Overlay = styled.div`
  display: none;
  ${mediaQueries.largeAndBelow} {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--color-bg-light-gray);
    opacity: 0;
    pointer-events: none;
    z-index: 50;
    ${props =>
      props['data-open'] &&
      `
      opacity: 0.8;
      pointer-events: auto;
    `};
  }
`

const CloseButton = styled.span`
  display: none;
  ${mediaQueries.largeAndBelow} {
    position: absolute !important;
    top: 51px;
    right: 30px;
    opacity: 1;
    cursor: pointer;
    color: white !important;
    display: block;
  }
`

class Nav extends React.Component {
  state = {
    isOpen: false,
  }
  onToggleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const { isOpen } = this.state
    return (
      <>
        <Overlay onClick={this.onToggleClick} data-open={isOpen} />
        <ScrollListener.Consumer>
          {({ scrolledUp, hasScrolled }) => (
            <Container isVisible={scrolledUp} hasScrolled={hasScrolled}>
              <NavButton onClick={this.onToggleClick}>
                <DiamondNavSvg />
              </NavButton>
              <StyledLink className="no-border" to="/">
                <LogoSvg />
              </StyledLink>
              <NavWrap data-open={isOpen}>
                <CloseButton
                  className="link no-border"
                  onClick={this.onToggleClick}
                >
                  close
                </CloseButton>
                <JumpNav
                  onToggleClick={this.onToggleClick}
                  items={jumpNavItems}
                />
              </NavWrap>
              <CrystalButtonWrap>
                <CrystalButton
                  to="https://www.amazon.com/Utomia-Legend-Beyond-Caitlin-Scholl/dp/1945293691"
                  text="buy now"
                  mobileHidden={true}
                />
              </CrystalButtonWrap>
            </Container>
          )}
        </ScrollListener.Consumer>
      </>
    )
  }
}

export default Nav
