import React from 'react'
import styled from 'styled-components';
import Link from './Link';
import CrystalButtonSVG from '../svg/buy-crystal.svg';
import FloatAnimation from '../Atom/FloatAnimation';
import * as mediaQueries from '../../styles/js/mediaQueries';

const Container = styled(Link)`
  position: relative;
  display: inline-block;
  animation: ${FloatAnimation} 4s ease-in-out infinite;
  margin-top: 5px;
  ${props => props['data-hidden'] && `
    ${mediaQueries.largeAndBelow} {
      animation: ${FloatAnimation} 0s ease-in-out infinite;
      margin-top: 0;
    }
  `}
  svg {
    .crystal-btn-stroke, .crystal-btn-bg {
      transition: stroke 0.3s ease-in, fill 0.3s ease-in;
    }
    ${props => props['data-hidden'] && `
      ${mediaQueries.largeAndBelow} {
        display: none;
      }
    `}
  }
  &:hover {
    svg {
      .crystal-btn-stroke {
        stroke: var(--color-ui-dark-purple);
      }
      .crystal-btn-bg {
        fill: var(--color-ui-purple);
      }
    }
  }
`
const Text = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-58%, -67%);
  ${props => props['data-hidden'] && `
    ${mediaQueries.largeAndBelow} {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      transition: color 0.3s ease;
      &:hover {
        color: var(--color-text-gold)
      }
    }
  `}
`

const CrystalButton = ({to, text, mobileHidden = false}) => (
  <Container className="no-border" to={to} data-hidden={mobileHidden}>
    <CrystalButtonSVG />
    <Text data-hidden={mobileHidden}>{text}</Text>
  </Container>
)


export default CrystalButton;