const smallestBreakpoint = 360;
const smallBreakpoint  = 480;
const mediumBreakpoint = 768;
const largeBreakpoint  = 1024;
const largerBreakpoint = 1280;
const extraLargeBreakpoint = 1440;
const extraExtraLargeBreakpoint = 2560;

const maxWidth = breakpoint => `@media (max-width: ${breakpoint}px)`;
const minWidth = breakpoint => `@media (min-width: ${breakpoint}px)`;

const smallAndBelow  = maxWidth(smallBreakpoint);
const mediumAndBelow = maxWidth(mediumBreakpoint);
const largeAndBelow  = maxWidth(largeBreakpoint);
const largerAndBelow = maxWidth(largerBreakpoint);
const extraLargeAndBelow = maxWidth(extraLargeBreakpoint);
const extraExtraLargeAndBelow = maxWidth(extraExtraLargeBreakpoint);

const mediumAndUp = minWidth(smallBreakpoint + 1);
const largeAndUp  = minWidth(mediumBreakpoint + 1);
const largerAndUp = minWidth(largeBreakpoint + 1);
const extraExtraLargeAndUp = minWidth(largerBreakpoint + 1);

export {
  smallestBreakpoint,
  smallBreakpoint,
  mediumBreakpoint,
  largeBreakpoint,
  maxWidth,
  minWidth,
  smallAndBelow,
  mediumAndBelow,
  mediumAndUp,
  largeAndUp,
  largeAndBelow,
  largerAndUp,
  largerAndBelow,
  extraLargeAndBelow,
  extraExtraLargeAndBelow,
  extraExtraLargeAndUp,
};