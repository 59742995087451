import _ from 'lodash';
import styled, { css } from 'styled-components';

const MAX_TRANSITION = 10;
const DEFAULT_IN_DURATION = 500;
const DEFAULT_OUT_DURATION = 500;
const STAGGER_DURATION = 250;

const constants = {
  STAGGER_DURATION
}

const outStyles = ({
  outDuration = DEFAULT_OUT_DURATION,
} = {}) => css`
  opacity: 0;
  transform: translateY(20px);
  will-change: opacity;
  transition: opacity ${outDuration}ms cubic-bezier(0.67, 0.00, 0.67, 1.00), transform 500ms cubic-bezier(0.33, 0.00, 0.00, 1.00);
`;

const inStyles = ({
  inDuration = DEFAULT_IN_DURATION,
} = {}) => css`
  opacity: 1;
  transform: translateY(0);
  transition-duration: ${inDuration}ms;
`;

const sharedStyles = ({
  inView,
  inDuration = DEFAULT_IN_DURATION,
  outDuration = DEFAULT_OUT_DURATION,
} = {}) => css`
  ${outStyles({ outDuration })}
  ${inView && inStyles({ inDuration })}
`;

const allStaggeredTransitionStyles = ({
  inView,
  stagger = STAGGER_DURATION,
  inDuration,
  outDuration,
}) => css`
  > * {
    ${sharedStyles({ inView, stagger, inDuration, outDuration })}
    ${_.range(MAX_TRANSITION).map(i => `
      &:nth-child(${i + 1}) {
        transition-delay: ${i * stagger}ms;
      }`)
    }

    // Fallback for items beyond the range
    &:nth-child(n + ${MAX_TRANSITION + 1}) {
      transition-delay: ${MAX_TRANSITION * stagger}ms;
    }

    &:nth-child(n) {
      ${!inView && `
        transition-delay: 0ms;
      `}
    }
  }
`;

const twoStepTransitionStyles = ({
  inView,
  stagger = STAGGER_DURATION,
  inDuration,
  outDuration,
}) => css`
  > * {
    ${props => sharedStyles(props)}

    &:nth-child(n + 2) {
      transition-delay: ${inView ? stagger : 0}ms;
    }
  }

  // Eyebrows and titles, if first 2 items transition in first
  > {
    h1, h2, h4 {
      &:nth-child(-n + 2) {
        transition-delay: 0ms;
      }
    }
  }
`;

/**
 * TextTransition {StyledComponent}
 * props.inView {boolean} - true to make content visible
 * props.stagger {Number} - Milliseconds to stagger transition steps. Default: 250
 * props.inDuration {Number} - Milliseconds for entrance transition duration. Default: 500
 * props.outDuration {Number} - Milliseconds for exit transition duration. Default: 500
 */
const TextTransition = styled.div`
  ${twoStepTransitionStyles}
`;

export {
  outStyles,
  inStyles,
  sharedStyles as transitionStyles,
  allStaggeredTransitionStyles,
  twoStepTransitionStyles,
  constants,
  TextTransition as default
};